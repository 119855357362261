<template>
  <div class="client-profile">
    <fund-owner-shares-page
      :page-title="pageTitle"
      :titles="titles"
      :cards="getOwnedShares"
      :total="total"
      @fillOut="$router.push('/cabinet/documents/forms/open-personal-account')"
      @download="download"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FundOwnerSharesPage from "../../../components/pages/cabinet/Funds/FundOwnerSharesPage";

export default {
  name: "FundsOwnedShares",
  components: { FundOwnerSharesPage },
  watch: {
    docChanged() {
      if (this.changedDocAlias === "appl-purchase_invest_shares" || this.changedDocAlias === "appl-redemption_invest_shares") {
        this.GET_FUNDS()
        this.GET_OWNED_SHARES();
      }
    },
    fondChanged() {
      this.GET_FUNDS()
      this.GET_OWNED_SHARES();
    }
  },
  computed: {
    ...mapGetters("FundsModule", ["getOwnedShares"]),
    ...mapGetters("AutoUpdateModule", ["docChanged", "changedDocAlias", "fondChanged"]),
    total() {
      return this.getOwnedShares.reduce((total, el) => (el.share_total_cost ? total+(+el.share_total_cost) : total), 0)
    }
  },

  data() {
    return {
      pageTitle: "Паи фондов во владении",
      titles: [
        { name: "fond_name", value: "Наименование фонда:" }, // Наименование фонда (ссылка на страницу фонда)
        { name: "share_price", value: "Цена пая:" }, // Цена пая (цена, по которой были приобретены паи)
        { name: "share_count", value: "Количество паев во владении:" }, // Количество паев во владении (количество приобретенных паев)
        { name: "cost", value: "Общая стоимость паев во владении:" }, // Общая стоимость паев во владении (по данному фонду)
        { name: "profitability_period", value: "Доходность за период:" }, // Доходность за период (рассчитываемый параметр на основании формулы, заданной в Системе, формула для расчета должна быть предоставлена Заказчиком на этапе разработки и не может использовать параметры, отсутствующие в БД; либо ссылка на файл xls, загруженный Администратором Системы)
        { name: "interim_payments_sum", value: "Промежуточные выплаты:" }, // Промежуточные выплаты (рассчитываемый параметр на основании формулы, заданной в Системе, формула для расчета должна быть предоставлена Заказчиком на этапе разработки и не может использовать параметры, отсутствующие в БД; либо ссылка на файл xls, загруженный Администратором Системы)
        { name: "file_id", value: "Уведомление:" }, // Уведомление (файл в формате pdf – уведомление от СпецРег о приобретении паев, загружается Администратором)
      ],
    };
  },
  methods: {
    ...mapActions("FundsModule", ["GET_OWNED_SHARES"]),
    ...mapActions("regModule", ["DOWNLOAD_FILE"]),
    ...mapActions("FundsModule", ["GET_FUNDS"]),
    download(link) {
      if (!link) {
        this.$toast.error("Уведомление не найдено");
      } else {
        this.DOWNLOAD_FILE(link);
      }
    },
  },
  mounted() {
    this.GET_FUNDS()
    this.GET_OWNED_SHARES();
  },
};
</script>

<style scoped lang="scss"></style>
