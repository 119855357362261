<template>
  <div class="documents">
    <div class="form-container">
      <div class="header">
        <h1>{{ PageTitle }}</h1>
        <p class="total">Общая стоимость всех паев: {{ total.toLocaleString() }} ₽</p>
      </div>
      <div v-if="cards && cards.length > 0" class="documents-cards">
          <rwm-shares-card
                  :title="card.fond_name"
                  :total-cost="card.share_total_cost"
                  :total-count="card.share_total_count"
                  :total-price="card.share_total_price"
                  :shares="card.user_invest_shares"
                  :actual-price="card.share_actual_price"
                  v-for="card in cards"
                  :key="card.id+card.fond_id"
                  @download="download"
          />
      </div>
      <div v-else class="col-12">
        <p class="anketa">У Вас нет паев во владении</p>
      </div>

      <rwm-info
        v-if="infoText"
        :msg="infoText"
        icon="warning"
        color="#DA291C"
      />
      <div :class="['documents-info']" v-if="infoBlock">
        <div class="anketa" v-html="infoBlock" />
      </div>
    </div>
  </div>
</template>

<script>
import RwmInfo from "../../../RWMInfo/RwmInfo";
import dateFormatter from "../../../../modules/module.date";
import RwmSharesCard from "../../../RWMSharesCard/RwmSharesCard";

export default {
  name: "FundOwnerSharesPage",
  components: {RwmSharesCard, RwmInfo },
  props: {
    total: [String, Number],
    PageTitle: String,
    infoBlock: String,
    infoText: String,
    messages: Array,

    cards: Array,
    titles: Array, //array from objects {name: '', value: ''} where name is a key from cards array
  },
  methods: {
    date(date) {
      return dateFormatter(date);
    },
    showFund(fundId) {
      this.$router.push(`/cabinet/funds/fund/${fundId}`);
    },
    download(file) {
      this.$emit('download', file)
    }
  },
};
</script>

<style scoped lang="scss">
.header {
  & .total {
    font-weight: normal;
    font-size: 21px;
    line-height: 29px;
    color: $RWM-red;
    margin-top: 15px;
  }
}
.documents {
  &-cards {
    display: flex;
    row-gap: 24px;
    column-gap: 32px;
    flex-direction: column;
  }
}

.fatca-card {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
  padding: 20px 32px;
  border: 1px solid $RWM-grey-super-light;
  border-radius: 5px;
  @media screen and (min-width: 1280px) {
    min-width: 450px;
    max-width: calc(50% - 16px);
  }

  & .alert {
    color: $RWM-red;
  }

  &.disabled {
    opacity: 0.65;
  }

  & .row {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 24px;
    justify-content: space-between;
    align-items: center;
    color: $RWM-dark;
    flex-direction: row;

    & .fond-head {
      color: $RWM-grey;
      transition: all 300ms;
      @media screen and (min-width: 1200px) {
        cursor: pointer;
        &:hover {
          color: $RWM-red;
        }
      }
    }
  }

  &__body {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    color: $RWM-grey;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 26px;
    flex-direction: column;
    row-gap: 20px;

    & .data-row {
      display: flex;
      column-gap: 25px;
      justify-content: center;
      flex-direction: column;
      @media screen and (min-width: 1400px) {
        flex-direction: row;
      }
      & > p {
        text-align: center;
        @media screen and (min-width: 1400px) {
          max-width: 50%;
          width: calc(50% - 10px);
          text-align: right;
        }
      }
      & .data {
        text-align: center;
        @media screen and (min-width: 1400px) {
          max-width: 50%;
          width: calc(50% - 10px);
          text-align: left;
          display: flex;
          align-items: flex-end;
        }
      }
    }

    & .titles {
      display: flex;
      flex-direction: column;
      row-gap: 15px;
    }

    & .titles {
      text-align: right;
      min-width: 180px;
    }

    & .hide {
      display: none;
    }
  }

  & .active {
    color: $RWM-red;
  }

  & .message {
    width: 100%;
    text-align: left;
    cursor: pointer;

    overflow: hidden;

    & p {
      font-weight: 500;
      font-size: 19px;
      line-height: 26px;
      overflow: hidden;

      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &:hover {
      max-height: 1000px;

      & p {
        white-space: normal;
      }
    }
  }
  & .message {
    max-height: 26px;
    transition: max-height 300ms;
  }
}
</style>
