<template>
    <section class="info" :style="{'color': color}">
        <v-icon size="xLarge" :hovering="false">{{icon}}</v-icon>
        <p class="info-message" v-html="msg" />
    </section>
</template>

<script>
    import VIcon from "../icons/VIcon";
    export default {
        name: "RwmInfo",
        components: {VIcon},
        props: {
            icon: String,
            msg: String,
            color: String
        }
    }
</script>

<style scoped lang="scss">
    .info {
        display: flex;
        column-gap: 24px;
        margin: 24px 0;
        align-items: flex-start;
        &-message {
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
        }
    }
</style>